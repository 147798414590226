import styled from "styled-components";
import StyledLink from "../../../../shared/components/global/StyledLink";
import { Button, ButtonSizes } from "../../../../shared/styles/button";
import {
  Container,
  ContainerContentStandard,
} from "../../../../shared/styles/container";
import { device } from "../../../../shared/styles/device";
import { DisciplinCard } from "./DisciplineCard";

const AreasSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f7;
  border-radius: 0px ${(props) => props.theme.borderRadiusSectionMobile}
    ${(props) => props.theme.borderRadiusSectionMobile} 0px;
  margin-bottom: 64px;
  margin-top: 64px;

  @media ${device.tablet} {
    border-radius: 0px ${(props) => props.theme.borderRadiusSection}
      ${(props) => props.theme.borderRadiusSection} 0px;
  }

  h2 {
    margin-bottom: 48px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  @media ${device.laptop} {
    padding-bottom: 64px;
  }
`;
export const AreasGrid = styled.div`
  column-gap: 0px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 48px;
  margin-top: 48px;
  row-gap: 24px;
  width: 100%;

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
  }
`;

export enum Discipline {
  ONLINE_MARKETING = "Online Marketing",
  DESIGN = "Design",
  TEXT = "Text",
  BERATUNG = "Beratung",
}

export const AreasSection = () => {
  return (
    <AreasSectionContainer>
      <Container>
        <ContainerContentStandard>
          <h2>
            In diesen <span>Disziplinen</span> kannst du dich bewerben.
          </h2>
          <AreasGrid>
            <DisciplinCard discipline={Discipline.ONLINE_MARKETING} />
            <DisciplinCard discipline={Discipline.DESIGN} />
            <DisciplinCard discipline={Discipline.TEXT} />
            <DisciplinCard discipline={Discipline.BERATUNG} />
          </AreasGrid>
          <ButtonContainer>
            <StyledLink href="/register/talent/">
              <Button primary size={ButtonSizes.m}>
                Jetzt kostenlos registrieren
              </Button>
            </StyledLink>
          </ButtonContainer>
        </ContainerContentStandard>
      </Container>
    </AreasSectionContainer>
  );
};
