import styled from "styled-components";
import { device } from "./device";

export const SubcategoryTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  column-gap: 8px;
  row-gap: 8px;
`;

interface SubcategoryTagProps {
  selected: boolean;
}

export const SubcategoryTag = styled.span<SubcategoryTagProps>`
    border: ${(props) => props.selected ? '2px' : '1px'} solid ${(props) => props.selected ? props.theme.colors.primary : 'rgba(102,95,130,.15)'};
    border-radius: 40px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
    padding: 8px 16px;
    cursor: pointer;
    user-select: none;

    &:hover {
        border: ${(props) => props.selected ? '2px' : '1px'} solid ${(props) => props.selected ? props.theme.colors.primary : props.theme.colors.primary};
    }

    @media ${device.tablet} {
      line-height: 21px;
      font-size: 16px;
      padding: 12px 24px;
    }
`;

interface SubcategoryTagReadOnlyProps {
  inverted?: boolean;
  small?: boolean;
  noMargin?: boolean;
}

export const SubcategoryTagReadOnly = styled.p<SubcategoryTagReadOnlyProps>`
    border: 1px solid ${(props) => props.inverted ? props.theme.colors.fontWhite + '30' : props.theme.colors.fontBlack};
    border-radius: 40px;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin: 0;
    padding: 8px 16px;
    user-select: none;
    color : ${(props) => props.inverted ? props.theme.colors.fontWhite : props.theme.colors.fontBlack};

        ${(props) => !props.small && `
    @media ${device.tablet} {
      line-height: 21px;
      font-size: 16px;
      padding: 12px 24px;
    }
        `};
`;