import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../shared/styles/device';
import { SubcategoryTagReadOnly } from '../../../../shared/styles/tag';
import { getTags } from '../../utils/tag';
import { Discipline } from './Areas';
import ConsultingSVG from '/public/svg/disciplines/consulting.svg';
import DesignerSVG from '/public/svg/disciplines/designer.svg';
import OnlineMarketingSVG from '/public/svg/disciplines/onlinemarketing.svg';
import TextSVG from '/public/svg/disciplines/text.svg';

const DisciplineCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => props.theme.borderRadiusBig};
  grid-column: span 1;
  min-height: 523px;
  padding: 36px;
  background-color: #FFFFFF;

  h3 {
    margin-top: 0px;
    margin-bottom: 21px;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }

  .subcategory-tags-card {
    z-index: 1;
  }
  
  svg {
    height: 100%;
    width: auto;
    margin: auto;
  }

  @media ${device.tablet} {
    grid-column: 'span 1';
  }
`;

const SVGContainer = styled.div`
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    height: 310px;
    display: flex;
    z-index: 100;
`;
const HideContainer = styled.div`
    position: absolute;
    bottom: 32px;
    left: 32px;
    right: 32px;
    top: 33%;
    z-index: 10;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 41.15%);
`;

const CardSubcategoryTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  
  @media ${device.tablet} {
    justify-content: flex-start;
    }
`;

interface DisciplinCardProps {
  discipline: Discipline;
}

export const DisciplinCard: React.FunctionComponent<DisciplinCardProps> = ({ discipline }) => {
  return (
    <DisciplineCardContainer>
      <h3>{discipline}</h3>
      <CardSubcategoryTags className="subcategory-tags-card">
        {getTags(discipline).map((tag) =>
          <SubcategoryTagReadOnly key={tag} small>{tag}</SubcategoryTagReadOnly>
        )}
      </CardSubcategoryTags>
      <HideContainer />
      <SVGContainer>
        {
          discipline === Discipline.ONLINE_MARKETING &&
          <OnlineMarketingSVG />
        }
        {
          discipline === Discipline.DESIGN &&
          <DesignerSVG />
        }
        {
          discipline === Discipline.TEXT &&
          <TextSVG />
        }
        {
          discipline === Discipline.BERATUNG &&
          <ConsultingSVG />
        }
      </SVGContainer>
    </DisciplineCardContainer >
  )
}
