import { Discipline } from "../model/talentpool/discipline";

export const getTags = (disziplin: Discipline | string): string[] => {
    switch (disziplin) {
        case Discipline.DESIGN:
            return ['Brand Design', 'Design Screen & Interfacedesign', 'Editorial Design', 'Grafik & Corporate Design', 'Illustration',
                'Mediengestaltung', 'Mobile & Webdesign', 'Mobile Design', 'Motiondesign/Anmiation & Video', 'Packaging Design', 'Postproduktion/Bildbearbeitung',
                'Präsentationsdesign', 'Produkt-Design', 'Print-Produktion', 'Prototyping', 'Reinzeichnung & DTP', 'UI Usability', 'UX Design', 'Webdesign', '3D Design', 'Service Design'];
        case Discipline.ONLINE_MARKETING:
            return ['Affiliate-Marketing', 'Amazon Marketing', 'App Marketing', 'Content Marketing', 'Data-Driven Marketing',
                'Digital Marketing', 'Display Marketing', 'E-Commerce', 'E-Mail-Marketing', 'Influencer Marketing', 'Performance Marketing',
                'Programmatic Advertising', 'SEO', 'SEA', 'SEM', 'Social Media Marketing'];
        case Discipline.TEXT:
            return ['Blogredaktion', 'Content Creator', 'Copy Writing', 'Creative Writing', 'Konzeption', 'Pressearbeit', 'Redaktion',
                'Redenschreiber*in', 'SEO Texter*in', 'Social Media Redaktion', 'Technical Writing', 'Werbetexten'];
        case Discipline.BERATUNG:
            return ['Account Management', 'Account Manager Digital', 'Etatdirektion', 'Konzeption', 'Kundenmanagement',
                'Mediaplanung', 'PR Management', 'Projektmanagement', 'Strategie'];
        default:
            return []
    }
}

export const getTagsFiltered = (disziplin: Discipline | string, discipline_subcategories: string[]): string[] => {

    switch (disziplin) {
        case Discipline.DESIGN:
            return ['Brand Design', 'Design Screen & Interfacedesign', 'Editorial Design', 'Grafik & Corporate Design', 'Illustration',
                'Mediengestaltung', 'Mobile & Webdesign', 'Mobile Design', 'Motiondesign/Anmiation & Video', 'Packaging Design', 'Postproduktion/Bildbearbeitung',
                'Präsentationsdesign', 'Produkt-Design', 'Print-Produktion', 'Prototyping', 'Reinzeichnung & DTP', 'UI Usability', 'UX Design', 'Webdesign', '3D Design', 'Service Design'];
        case Discipline.ONLINE_MARKETING:
            let onlineMarketing = ['Affiliate-Marketing', 'Amazon Marketing', 'App Marketing', 'Content Marketing', 'Data-Driven Marketing',
                'Digital Marketing', 'Display Marketing', 'E-Commerce', 'E-Mail-Marketing', 'Influencer Marketing', 'Performance Marketing', 'Programmatic Advertising', 'SEO', 'SEA', 'SEM', 'Social Media Marketing'];
            if (discipline_subcategories != null && discipline_subcategories.includes('Social Media')) {
                onlineMarketing.push('Social Media');
            }
            if (discipline_subcategories != null && discipline_subcategories.includes('Online-Marketing')) {
                onlineMarketing.push('Online-Marketing');
            }
            return onlineMarketing;
        case Discipline.TEXT:
            return ['Blogredaktion', 'Content Creator', 'Copy Writing', 'Creative Writing', 'Konzeption', 'Pressearbeit', 'Redaktion',
                'Redenschreiber*in', 'SEO Texter*in', 'Social Media Redaktion', 'Technical Writing', 'Werbetexten'];
        case Discipline.BERATUNG:
            return ['Account Management', 'Account Manager Digital', 'Etatdirektion', 'Konzeption', 'Kundenmanagement',
                'Mediaplanung', 'PR Management', 'Projektmanagement', 'Strategie'];
        default:
            return []
    }
}